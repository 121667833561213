<template>
  <v-card>
    <v-container>
      <v-card-title>
        Create Activity
      </v-card-title>

      <v-select
        label="Select Activity Type"
        :items="type"
        item-text="name"
        item-value="_id"
        v-model="activityData.type"
      />

      <SurveyForm v-if="activityData.type == 'S'" :activityData="activityData" />
      <TaskForm v-if="activityData.type == 'T'" :activityData="activityData" />
      <v-btn class="primary mt-4" @click="createActivity">Create</v-btn>
    </v-container>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
import SurveyForm from "./surveyForm.vue";
import TaskForm from "./taskForm.vue";
const service = new RestResource();

export default {
  components: {
    SurveyForm,
    TaskForm
  },
  data() {
    return {
      type: [
        { _id: "T", name: "Task" },
        { _id: "S", name: "Survey" }
      ],
      activityData: {
        config: {}
      }
    };
  },

  methods: {
    createActivity() {
      if (this.$validator.validateAll()) {
        this.$setLoader();

        service.createActivity(this.activityData).then(res => {
          this.$showSuccessMessage(res.data);
          this.$router.push({ name: "MobileActivityIndex" });
        });
      }
    }
  }
};
</script>